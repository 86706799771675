<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(itemData.docTitle) }}</span>
      </h2>
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        :approval-line="approvalLine"
        :doc-no="docNo"
        :doc-flag="'DR'"
        :isViewer="false"
        :viewerList.sync="viewerList"
        :dateTimeFormat="dateTimeFormat"
        @searchOrganization="searchOrganization"
        @popDeleteViewer="popDeleteViewer"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->
    <DraftReqeustInfo
      :draftData="itemData"
      :isCompleteStep="true"
      :isView="true"
      :statementTotalPrice="statementTotalPrice"
      :purchaseHistoryDataList="itemData.purchaseHistory"
      @onClickPopPriceAdd="onClickPopPriceAdd"
      @onClickPopPriceAddHistory="onClickPopPriceAddHistory"
      @onClickPopStatementHistory="onClickPopStatementHistory"
    />
    <!-- 구매요청품목 -->
    <!-- <DraftPurchaseGoodsList v-if="model.assetType === assetType.INFRA_ASSET" :draftData="model" /> -->
    <!-- 정산정보 -->
    <!-- <DraftPurchasePayment :draftData="model" /> -->
    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />
    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>
      <div class="pos_right">
        <!-- <button type="button" class="btn_large btn_primary" @click.prevent="confirmReDraft">
          재상신
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import Radio from "@/_approval/components/common/radio/Radio";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
// import DraftPurchaseGoodsList from '@/_approval/components/purchaseDraft/view/DraftPurchaseGoodsList';
// import DraftPurchasePayment from '@/_approval/components/purchaseDraft/view/DraftPurchasePayment';
import DraftFile from "@/_approval/components/purchaseDraft/view/DraftFile";
import PopPriceAdd from "@/_approval/components/purchaseDraft/view/popup/PopPriceAdd";
import PopPriceAddHistory from "@/_approval/components/purchaseDraft/view/popup/PopPriceAddHistory";
import PopStatementHistory from "@/_approval/components/purchaseDraft/view/popup/PopStatementHistory";
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import commDocs from "@/_approval/mixins/common/commDocs";
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";

import ApiService from "@/services/ApiService";
import { mapState, mapGetters } from "vuex";

import {
  assetType,
  docStatus,
  targetGubun,
  aprvlLineType,
  approvalStatus,
} from "@/_approval/const/const.js";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  name: "DraftRejectDetail",
  mixins: [PurchaseRequestDetailMixin, commDocs],
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.vlogin,
    }),
    rejectYn: function () {
      return this.approvalLine.some((e) => !_.isEmpty(e.reason));
    },
  },
  components: {
    PayStatus,
    RejectComment,
    DraftReqeustInfo,
    // DraftPurchaseGoodsList,
    // DraftPurchasePayment,
    DraftFile,
    PopPriceAdd,
    PopPriceAddHistory,
    PopStatementHistory,
    PdfViewer,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs],
  data() {
    return {
      model: new DraftWriteModel(),
      approvalLine: [],
      priceAddHistories: [],
      assetType: assetType,
      statementTotalPrice: 0,
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetGubun: "",
      docStatus: Object.freeze(docStatus),
      docFlag: "DR",
    };
  },
  created() {
    this.getData();
  },
  async mounted() {
    const data = await this.fetchStoredDraftData(this.docNo);

    // this.model.initFromFetchData(data);
    this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(data.drftDeptCode);
    this.approvalLine = data.aprvlLineList;
  },
  methods: {
    confirmReDraft() {
      this._showLayer(
        { type: "confirm", content: "해당 문서를 재상신 하시겠습니까?" },
        this.reDraft,
      );
    },

    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      this.updateRead();
      return;
    },
    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.itemData.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    async reDraft() {
      this.model.docStatus = docStatus.TEMPORARY;
      this.model.reDrftOriDocNo = this.docNo;

      this.model.draftDeptApprLine = this.getApprovalLineFilteredByType(aprvlLineType.DRAFT);
      this.model.processingApprovalLine = this.getApprovalLineFilteredByType(
        aprvlLineType.APPROVAL,
      );
      this.model.referenceApprovalLine = this.getApprovalLineFilteredByType(
        aprvlLineType.REFERENCE,
      );

      await this.$router.push(
        `${this.$paymentRouterPath.PAYMENT_DRAFT_TEMP}/${this.$route.params.id}`,
      );
    },
    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_DRAFT_REJECT);
    },
  },
};
</script>
